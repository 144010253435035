import { Box } from 'grommet';

import { ProcessedCard } from '../components/assistant-card/assistant-card';

const Card = ({ metadata, data }) => {
  return (
    <Box fill="vertical">
      <ProcessedCard metadata={metadata} data={data} shadow={false} sidebar={true} />
    </Box>
  );
};

export default Card;
